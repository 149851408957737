import React from 'react';
import { useTranslation } from "react-i18next";

import UnderLine from "../shared/UnderLine";

const Chart = () => {
  const { t } = useTranslation("home", { keyPrefix: 'Chart' });
  return (
    <section className="bg-primary text-white">
      <h2 className='title text-center'>
        {t("title")}
        <UnderLine className="bg-white mb-4"/>
      </h2>
      <p className="text-center mb-4 h5">{t("subtitle")}</p>

      <div className="mb-6 text-center mx-auto flex-center gap-4 flex-wrap">
        {/*<a href="https://www.myfxbook.com/members/drjec/steadyfx-micro/11050454">*/}
        {/*  <img alt="widget" src="https://widgets.myfxbook.com/widgets/11050454/large.jpg" className="mw-100"/>*/}
        {/*</a>*/}
        {/*<a href="https://www.myfxbook.com/members/drjec/balancefx-micro/11050487">*/}
        {/*  <img alt="widget" src="https://widgets.myfxbook.com/widgets/11050487/large.jpg" className="mw-100"/>*/}
        {/*</a>*/}
        <a href="https://www.myfxbook.com/members/halfsoft/balancefx-micro/11099348">
          <img alt="widget" src="https://widgets.myfxbook.com/widgets/11099348/large.jpg" className="mw-100"/>
        </a>
        {/*<a href="https://www.myfxbook.com/members/drjec/maxifx-micro/10376853">*/}
        {/*  <img alt="widget" src="https://widgets.myfxbook.com/widgets/10376853/large.jpg" className="mw-100"/>*/}
        {/*</a>*/}
      </div>
    </section>
  )
}
export default Chart;
